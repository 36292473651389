



















































































































































































































































































import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      twitterInviteUsername: this.$t('invite-tweet.placeholder').toString(),
    };
  },
  meta() {
    const title = this.$t('title').toString();

    return {
      title,
    };
  },
  computed: {
    twitterTweetIntentUrl() {
      // * IDEA: Randomly pick one of multiple strings
      // * IDEA: Use current locale for tweet text
      // * IDEA: Use FormInput with Button-addon, allow entering twitter handle on-site

      const text = this.$t('invite-tweet.text', {
        username: this.twitterInviteUsername.replace('@', ''),
      }).toString();

      const related = [
        'AuftragApp',
        // 'UNOVYHQ', // ? Also advertise company
        // 'floriangaerber', // ? Also advertise myself
      ];
      const hashtags = [
        'AuftragApp',
        'CommissionsMadeSimple',
        // TODO Find relevant hashtags
      ];

      let url = 'https://twitter.com/intent/tweet';

      url += `?text=${encodeURIComponent(text)}`;
      url += `&url=${encodeURIComponent(process.env.VUE_APP_BASE_URL || '')}`;
      url += `&related=${related.join(',')}`;
      url += `&hashtags=${hashtags.join(',')}`;

      return url;
    },
  },
});
